import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Offline from '../Offline';
import { shuffle } from '../../utils';
import { FormControl, InputLabel } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import "./index.scss"

const Main = ({ startQuiz }) => {
  const [countdownTime, setCountdownTime] = useState({
    hours: 0,
    minutes: 120,
    seconds: 0,
  });

  const [pl, setPl] = useState('python')
  const [offline, setOffline] = useState(false);

  const defaultTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });


  const handleChange = (val) => {
    setPl(val.target.value)
  }

  if (offline) return <Offline />;

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const formFields = {
      name: data.get("fullname"),
      companyName: data.get("company"),
      email: data.get("email"),
      designation: data.get("designation"),
      language: pl,
      phone: data.get("phoneNumber"),
    };

    startQuiz(
      formFields,
      countdownTime.hours + countdownTime.minutes + countdownTime.seconds
    );

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container className="formContainer" component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 5,
            marginBottom: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h4"
            sx={{ paddingTop: 4, fontSize: "32px" }}
            variant="h5"
          >
            Battle Of Code Registration
          </Typography>
          <FormControl component="form" onSubmit={handleSubmit} sx={{ mt: 2,  '.MuiInputBase-input': { fontSize: '1.1rem' },}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="fullname"
                  required
                  fullWidth
                  id="fullname"
                  label="Full Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="company"
                  label="Company Name"
                  name="company"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="designation"
                  label="Designation"
                  name="designation"
                  autoComplete="designation"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  id="phoneNumber"
                  label="Phone Number"
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Programming Language
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pl}
                    label="Programming Language"
                    onChange={handleChange}
                  >
                    <MenuItem value={"C"}>C</MenuItem>
                    <MenuItem value={"C++"}>C++</MenuItem>
                    <MenuItem value={"python"}>Python</MenuItem>
                    <MenuItem value={"Ruby"}>Ruby</MenuItem>
                    <MenuItem value={"javascript"}>JavaScript</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#4681f4",
                color: "white",
                fontSize: 16,
                borderRadius: "5px",
              }}
            >
              Start
            </Button>
          </FormControl>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

Main.propTypes = {
  startQuiz: PropTypes.func.isRequired,
};

export default Main;
