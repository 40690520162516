import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import "./index.scss"
import Footer from '../Footer';

const Layout = ({ endQuiz, children }) => {

  // useEffect(()=> {
  //   const colors = [
  //     '#711c91',
  //     '#ea00d9',
  //     '#0abdc6',
  //     '#133e7c'
  //   ];
    
  //   const createSquare = () => {
  //     const section = document.querySelector("section");
  //     const square = document.createElement('span');
  //     square.className = "item-check"
      
  //     const size = Math.random() * 50;
      
  //     square.style.width = 20 + size + 'px';
  //     square.style.height = 20 + size + 'px';
      
  //     square.style.top = Math.random() * window.innerHeight + 'px';
  //     square.style.left = Math.random() * window.innerWidth + 'px';
      
  //     square.style.background = colors[Math.floor(Math.random() * colors.length)];
  //     section.appendChild(square);
      
  //     setTimeout(() => {
  //       square.remove()
  //     }, 5000);
  //   }
    
  //   setInterval(createSquare, 150);
  // }, [])
  return (
    <Fragment>
      <div
        style={{
          zIndex: 99,
          position: "fixed",
          width: "100vw",
          height: "100vh",
          overflow: 'auto'
        }}
      >
        <div
          style={{
            minHeight: "100vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: "auto",
          }}
        >
          <Header endQuiz={endQuiz}/>
          <div>{children}</div>
          <Footer />
        </div>
      </div>

      <section style={{ zIndex: 5 }}></section>
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
