import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Button } from 'semantic-ui-react';

import ShareButton from '../ShareButton';
import { calculateScore, calculateGrade, timeConverter } from '../../utils';
import "./stats.scss"

const Stats = ({
  data,
  resetQuiz,
}) => {
  return (
    <Segment className="resultContainer">
      <div className="tableContainer">
        <table class="ui celled table">
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Full Name</th>
              <th>Designation</th>
              <th>Email</th>
              <th>Programing Language</th>
              <th>Correct Answer</th>
              <th>Incorrect Answer</th>
            </tr>
          </thead>
          <tbody>
          
              {data.map((data, idx) => {
                return (
                  <tr key={idx}>
                    <td data-label="Company Name">{data.company_name}</td>
                    <td data-label="Full Name">{data.full_name}</td>
                    <td data-label="Designation">{data.designation}</td>
                    <td data-label="Email">{data.email}</td>
                    <td data-label="Programing Language">  {data.programming_language}</td>
                    <td data-label="Correct Answer">{data.correct_answers}</td>
                    <td data-label="Incorrect Answer">{data.incorrect_answers} </td>
                    </tr>
                );
              })}
           
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: 35 }}>
        {/* <Button
          primary
          content="Play Again"
          onClick={replayQuiz}
          size="big"
          icon="redo"
          labelPosition="left"
          style={{ marginRight: 15, marginBottom: 8 }}
        /> */}
        <Button
          color="teal"
          content="Back to Home"
          onClick={resetQuiz}
          size="big"
          icon="home"
          labelPosition="left"
          style={{ marginBottom: 8 }}
        />
      </div>
    </Segment>
  );
};

// Stats.propTypes = {
//   totalQuestions: PropTypes.number.isRequired,
//   correctAnswers: PropTypes.number.isRequired,
//   timeTaken: PropTypes.number.isRequired,
//   replayQuiz: PropTypes.func.isRequired,
//   resetQuiz: PropTypes.func.isRequired,
// };

export default Stats;
