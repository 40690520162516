import React, { useEffect, useState } from 'react';

import Layout from '../Layout';
import Loader from '../Loader';
import Main from '../Main';
import Quiz from '../Quiz';
import Result from '../Result';

import { shuffle } from '../../utils';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getLeaderBoard, submitAnswer, submitUser } from '../../api/apiCall';
import Landing from '../Landing/Landing';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from '../Login/Login';


const App = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [data, setData] = useState(null);
  const [countdownTime, setCountdownTime] = useState(null);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [resultData, setResultData] = useState(null);
const navigation = useNavigate()
  const startQuiz = async (formData, countdownTime) => {
    try{
    setLoading(true);
    setLoadingMessage({
      title: "Loading your quiz...",
      message: "It won't be long!",
    });
    const data = await submitUser(formData);

    const { options, correct_answer, question } = data;
    if (data) {
      setCountdownTime(countdownTime);
      setData([
        {
          options,
          correct_answer,
          question,
        },
      ]);
      setIsQuizStarted(true);
      setLoading(false);
    }
  }catch(e){
    toast.error("Something Went Wrong", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }
  };

  const nextQuestion = async (selectedAns) =>{
    try{
    setLoading(true);

   const isCorrect = data[0].correct_answer === selectedAns;
   const res = await submitAnswer(isCorrect)
   const { options, correct_answer, question } = res;
    if (data) {
      setData([
        {
          options,
          correct_answer,
          question,
        },
      ]);
      setLoading(false);
    }
  }catch(e){
    toast.error("Something Went Wrong Please", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  }
 

  const endQuiz = async () => {
    navigation("quiz");
    setLoading(true);
    setLoadingMessage({
      title: 'Fetching your results...',
      message: 'Just a moment!',
    });
    const leaderBoardData =  await getLeaderBoard();
    setTimeout(() => {
      setIsQuizStarted(false);
      setIsQuizCompleted(true);
      setLoading(false);
      setResultData(leaderBoardData);
    }, 2000);
  };

  const replayQuiz = () => {
    setLoading(true);
    setLoadingMessage({
      title: 'Getting ready for round two.',
      message: "It won't take long!",
    });

    const shuffledData = shuffle(data);
    shuffledData.forEach(element => {
      element.options = shuffle(element.options);
    });

    setData(shuffledData);

    setTimeout(() => {
      setIsQuizStarted(true);
      setIsQuizCompleted(false);
      setResultData(null);
      setLoading(false);
    }, 1000);
  };

  const resetQuiz = () => {
    setLoading(true);
    setLoadingMessage({
      title: 'Loading the home screen.',
      message: 'Thank you for playing!',
    });

    setTimeout(() => {
      setData(null);
      setCountdownTime(null);
      setIsQuizStarted(false);
      setIsQuizCompleted(false);
      setResultData(null);
      setLoading(false);
    }, 1000);
  };

  return (
    <Layout endQuiz={endQuiz}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="quiz"
          element={
            <>
              {loading && <Loader {...loadingMessage} />}
              {!loading && !isQuizStarted && !isQuizCompleted && (
                <Main startQuiz={startQuiz} />
              )}
              {!loading && isQuizStarted && (
                <Quiz
                  data={data}
                  countdownTime={countdownTime}
                  endQuiz={endQuiz}
                  nextQuestion={nextQuestion}
                />
              )}
              {!loading && isQuizCompleted && (
                <Result
                  data={resultData}
                  replayQuiz={replayQuiz}
                  resetQuiz={resetQuiz}
                />
              )}
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </Layout>
  );
};

export default App;
