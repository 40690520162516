
import Axios from "./axiosInstance";


export const login = async (payload) => {
  const res = await Axios.post("/login", payload);
  return res.data
}

const token = localStorage.getItem('token')
export const submitUser = async (data) => {
  const { name, companyName, email, designation, language, phone } = data;
  const payload = {
    full_name: name,
    company_name: companyName,
    email: email,
    designation: designation,
    programming_language: language,
    phone_no: phone,
  };

  localStorage.setItem('email', email)
  localStorage.setItem('language', language)
  const res = await Axios.post("/submit_user", payload, {headers:{"Authorization" : `Bearer ${token}`}});
  return res.data
};

export const submitAnswer = async (isCorrect) => {
  const payload = {
    value: String(isCorrect),
    curr_user: localStorage.getItem('email'),
    programming_language: localStorage.getItem('language')
  };

  const res = await Axios.post("/submit_answer", payload,  {headers:{"Authorization" : `Bearer ${token}`}});
  return res.data
};


export const getLeaderBoard = async () => {
  const res = await Axios.get(
    "/leaderboard", {headers:{"Authorization" : `Bearer ${token}`}});
  return res.data
};

