import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Segment,
  Item,
  Divider,
  Button,
  Icon,
  Message,
  Header,
} from 'semantic-ui-react';
import he from 'he';

import Countdown from '../Countdown';
import { getLetter } from '../../utils';

import "./index.scss"
import Loader from '../Loader';
import { submitAnswer } from '../../api/apiCall';
import { toast } from 'react-toastify';

const Quiz = ({ data, countdownTime, endQuiz, nextQuestion }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState("");
  const [userSlectedAns, setUserSlectedAns] = useState(null);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [timeTaken, setTimeTaken] = useState(null);
  const [question, setQuestion] = useState(data)
  const [loading, setLoading] = useState(false)
  const [showAnser, setShowAnser] = useState(false)

useEffect(()=> {
  setCorrectAnswers(question[0].correct_answer)
}, [question])

  const handleItemClick = (name) => {
    if(!showAnser){
      setUserSlectedAns(name);
    }
  };

  const toastObj = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "colored",
  }

  const handleNext = async () => {
    setShowAnser(true);
    const isCorrect = question[0].correct_answer === userSlectedAns;
    setTimeout(async () => {
      setShowAnser(false);
      setLoading(true);
      if (isCorrect) {
        toast.success("Correct! Well done", toastObj);
       
      } else {
        toast.error("Wrong answer. Keep going!", toastObj);
      }

      const res = await submitAnswer(isCorrect);
      const { options, correct_answer, question: ResQuestion } = res;
      if (data) {
        setQuestion([
          {
            options,
            correct_answer,
            question: ResQuestion,
          },
        ]);
        setLoading(false);
        setUserSlectedAns(null);
      }
    }, 5000);

    
  };

  const timeOver = timeTaken => {
    return endQuiz({
      totalQuestions: data.length,
      correctAnswers,
      timeTaken,
      questionsAndAnswers,
    });
  };


  const getOptionClass = (decodedOption) => {
    if(showAnser && correctAnswers === decodedOption ){
      return "optionContainer-option optionContainer-option-correct";
    }
    if (
      showAnser &&
      userSlectedAns === decodedOption &&
      correctAnswers !== decodedOption
    ) {
      return "optionContainer-option optionContainer-option-wrong";
    }

   if(userSlectedAns === decodedOption){
    return "optionContainer-option optionContainer-option-active"
   }
   else {
    return "optionContainer-option"
   }

  }
 

  return (
    
      <Container>
        <Segment className="backgroundBlur">
          <Item.Group divided>
            <Item>
              <Item.Content>
                <Item.Extra>
                  <Header
                    as="h1"
                    block
                    floated="left"
                    className="contentContainer"
                  >
                    <Icon name="info circle" />
                    <Header.Content>{`Question`}</Header.Content>
                  </Header>
                  <Countdown
                    countdownTime={countdownTime}
                    timeOver={timeOver}
                    loading={loading || showAnser}
                    setTimeTaken={setTimeTaken}
                  />
                </Item.Extra>
                {loading ? (
                     <Item.Meta><Loader /> </Item.Meta>
                ) : (
                  <>
                    <br />
                    <Item.Meta>
                      <Message
                        size="huge"
                        floating
                        className="quiestionHeading"
                      >
                        <b>{`Q. ${he.decode(question[questionIndex].question)}`}</b>
                      </Message>
                      <br />
                      <Item.Description className="contentContainer">
                        <h3>Please choose one of the following answers:</h3>
                      </Item.Description>
                      <Divider />
                      <div className="optionContainer">
                        {question[questionIndex].options.map((option, i) => {
                          const letter = getLetter(i);
                          const decodedOption = he.decode(option);

                          return (
                            <span
                              className={getOptionClass(decodedOption)}
                              key={decodedOption}
                              style={{pointerEvents: showAnser ? 'none' : ''}}
                              name={decodedOption}
                              onClick={() => handleItemClick(decodedOption)}
                            >
                              <b style={{ marginRight: "8px" }}>{letter}</b>
                              {decodedOption}
                            </span>
                          );
                        })}
                      </div>
                    </Item.Meta>
                    <Divider />
                    <Item.Extra>
                      <Button
                        primary
                        content="Next"
                        onClick={handleNext}
                        floated="right"
                        size="big"
                        icon="right chevron"
                        labelPosition="right"
                        disabled={!userSlectedAns || showAnser}
                      />
                    </Item.Extra>
                  </>
                )}
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <br />
      </Container>
   
  );
};

Quiz.propTypes = {
  data: PropTypes.array.isRequired,
  countdownTime: PropTypes.number.isRequired,
  endQuiz: PropTypes.func.isRequired,
};

export default Quiz;
