import React from 'react'
import "./index.scss"
import { Menu } from 'semantic-ui-react'

const Footer = () => {
  return (
   
        <div class="footer">
          <div id="footersocial" className='footer-iconContainer'>
            <a
              href="https://www.facebook.com/bluetickconsultants/"
              title="Facebook"
              //   target="_blank"
              rel="noopener"
            >
              <i class="fa fa-facebook social-icons" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.instagram.com/bluetickconsultants/"
              title="Instagram"
              //   target="_blank"
              rel="noopener"
            >
              <i class="fa fa-instagram social-icons" aria-hidden="true"></i>
            </a>
            {/* <a
              href="https://www.threads.net/@bluetickconsultants"
              title="Threads"
              //   target="_blank"
              rel="noopener"
            >
              <span class="social-icons">
                <img src="images/threads.svg" alt="" />
              </span>
            </a> */}
            <a
              href="https://twitter.com/BluetickConsult"
              title="Twitter"
              //   target="_blank"
              rel="noopener"
            >
              <i class="fa fa-twitter social-icons" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/bluetick-consultants"
              title="Linkedin"
              //   target="_blank"
              rel="noopener"
            >
              <i class="fa fa-linkedin social-icons" aria-hidden="true"></i>
            </a>
            <a
              href="https://www.quora.com/profile/Bluetick-Consultants"
              title="Quora"
              //   target="_blank"
              rel="noopener"
            >
              <i class="fa fa-quora social-icons" aria-hidden="true"></i>
            </a>
          </div>
          <div
            id="footersocial"
          >
            <span id="copyright"> 2023</span>© All rights reserved by Bluetick
            Consultants LLP
          </div>
        </div>
     
  );
}

export default Footer