import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu } from 'semantic-ui-react';


const Header = ({endQuiz}) => {
const [showLoginBtn, setShowLoginBtn] = useState(true)
useLayoutEffect(() => {
  if (localStorage.getItem("token")) {
    setShowLoginBtn(false);
  }
}, [setShowLoginBtn]);


  return (
    <div className="header-top">
      <Link to="/">
        <div>
          <img
            style={{ width: "180px", padding: "5px" }}
            src={require("../../images/blueticklogo.png")}
            alt="logo"
          />
        </div>
      </Link>
      <div>
        <Button
          color="blue"
          content="Leaderboard"
          onClick={endQuiz}
          size="medium"
          icon="chart line"
          labelPosition="left"
          style={{ marginBottom: 8 }}
        />
        {showLoginBtn && (
          <Link to="/login">
            <Button
              color="green"
              content="Login"
              onClick={() => {}}
              size="medium"
              icon="sign in alternate"
              labelPosition="left"
              style={{ marginBottom: 8 }}
            />
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
