import React from "react";
import "./landing.scss";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
const Landing = () => {
  return (
    <div className="landingContainer">
      <div className="landingContainer-textContainer">
        <span className="landingContainer-textContainer-big">
          Battle Of Codes
        </span>
        <span className="landingContainer-textContainer-mid">
          Win exciting prices
        </span>
        <div>
          <Link role="button" to="/quiz">
            <Button
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#4681f4",
                color: "white !important",
                fontSize: 16,
                borderRadius: "5px",
              }}
            >
              Start
            </Button>
          </Link>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Landing;
