import React, { useEffect, useRef, useState } from 'react';
import { Container, Message, Icon } from 'semantic-ui-react';



// Example usage with an array of messages
const messagesArray = ["Hold tight, loading...", "Just a moment, getting ready...", "Loading the next question..."];;



const Loader = ({ title, message }) => {
  const msgRef = useRef()
  const [currentMsg, setCurrentMsg] = useState("")
  useEffect(() => {
    function displayMessages(messages) {
      let currentIndex = 0;
    
      function showMessage() {
        
        // Move to the next message or reset to the first one if it's the last message
        currentIndex = (currentIndex + 1) % messages.length;
        console.log(messages[currentIndex]);
        setCurrentMsg(messages[currentIndex])
      }
      showMessage();
    
   msgRef.current = setInterval(showMessage, 5000);
    
      
    }
    
      displayMessages(messagesArray);

      return () => {
        clearInterval(msgRef.current)
      }
    
  }, []);

  return (
    <Container className="loaderContainer">
      <Message icon size="big">
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>{title ? title : "Just one second"}</Message.Header>
          {/* {message ? message : 'We are fetching that content for you.'} */}
          {currentMsg}
        </Message.Content>
      </Message>
    </Container>
  );
}

export default Loader;
